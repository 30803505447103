.dashboard-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);

  .map-container {
    flex: 1;
    position: relative;
    display: flex;

    .site-list {
      width: 300px;
      background: white;
      z-index: 1;
      display: flex;
      flex-direction: column;

      .site-search {
        padding: 1rem;
      }

      .site-list-list {
        flex: 1;
        overflow-y: auto;
        padding: 0 1rem;

        .site-listing {
          margin-bottom: 1rem;
          width: 100%;
          text-align: left;

          h4 {
            margin: 0;
          }

          address {
            font-style: normal;
            margin-top: 0.5rem;
          }
        }
      }

    }
  }
}
.hotspot-page {
  .mapboxgl-popup {
    width: 350px !important;
  }
}

