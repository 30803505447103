.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .page-header {
    flex-grow: 1;
  }
}

.selected-property {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin-top:0;
    margin-bottom: 0;
  }

  .property-name {
    flex-grow: 1;
  }
}

.vendor-listing {
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    h3 {
      margin-top:0.5rem;
      margin-bottom: 0;
    }
  }

}