.selected-property {
  table {
    width: 100%;
    padding:0.5rem;

    th, td {
      padding:0.5rem;
      text-align:left;
    }
  }
}