.contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  gap:0.5rem;
  width:100%;
  max-width: 800px;
  > div {
    width:175px;
    margin-bottom:0.5rem;
    padding:0.5rem;
    display:unset;
  }
  h4 {margin:0; padding:0;}
}
.mapboxgl-popup {
  width:50vw;
  max-width: 100vw !important;
}
.mapboxgl-popup-content {
  background:#f9fafc;
}

