@import '~@fsg/gui-bits/dist/esm/main.css';

* {
  box-sizing: border-box;
}

html, body {
  background: #F9FAFC;
}

body {
  margin: 0;
  font-family: 'Barlow';
}

.error {
  color: #F04533;
  padding: 20px;
}
