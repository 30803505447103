.app-wrapper {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: stretch;
  max-width: 100%;
}

.navigation {
  padding: 30px;
  padding-right: 0;
  margin-right: 18px;
  flex: 0 0 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .logo {
    display: block;
    margin-bottom: 33px;
  }
  .navigation-links {
    min-height: 0;
    flex: 1 1 0;
    overflow: auto;
    display:flex;
    flex-direction: column;
    flex-basis:100vh;
  }

  .nav-label {
    margin-bottom: 12px;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  .nav-item {
    margin-bottom: 12px;
  }

  .nav-label,
  .nav-item {
    transition: all 0.3s ease;
  }

  .spacer {
    flex-grow: 1;
  }

  .user {
    background: none !important;
    margin-top:1rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top:1rem;

    .button-refresh {
      svg {
        padding-right: 3px;
      }
    }
    .button-clear {
      border: 1px solid #f04533; // var.$system-error
      color: #f04533; // var.$system-error
    }

    .button-clear.disabled {
      // match library styles
      color: #acafb5;
      border-color: rgba(172, 175, 181, 0.3);
    }
    .button-refresh.disabled {
      path {
        stroke: #acafb5;
      }
    }
  }

  .blurred {
    filter: blur(2px);
    opacity: 0.5;
    pointer-events: none;
  }
}

main {
  padding: 30px;
  max-width: calc(100% - 216px);
  flex-grow: 1;
  overflow: auto;

  &.public {
    max-width: 100%;
    width:100%;
  }
}

@media (min-width: 1001px) {
  .mobile-navigation {
    display: none;
  }
}

@media (max-width: 1000px) {
  .navigation {
    position: fixed;
    z-index: 11;
    background: #fff;
    top: 0;
    bottom: 0;
    left: -100vw;
    padding-right: 30px;
    max-width: none;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    padding: 20px;
    transition: 0.1s all ease-in-out;

    &.mobile-open {
      left: 0;
    }
  }

  main {
    max-width: 100%;
    padding-bottom: 76px;
  }

  .mobile-navigation {
    height: 76px;
    box-shadow: -5px 0 25px rgb(0 0 0 / 10%);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 10;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
  }
}

.map-container {
  display:flex;
  overflow:hidden;

  .site-list {
    width:400px;
    height:80vh;
    padding-top:3rem;
    position:relative;

    .site-search {
      position:absolute;
      top:0;
      left:0;
      right:0;
      padding:0.5rem 0;

      * {
        display:block;
      }
    }

    .site-list-list {
      height:100%;
      overflow:auto;
    }

    .site-listing {
      display:block;
      margin-bottom:0.5rem;

      h4 {
        margin:0;
      }
    }
  }
}

@media (max-width:700px){
  .map-container {
    flex-direction:column;

    .site-list {
      width:100%;
      height:15vh;
      padding-top:3rem;
    }
  }
}